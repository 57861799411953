<template>
  <v-autocomplete
    v-model="customerId"
    clearable
    filled
    outlined
    label="Composição"
    :items="customers"
    :loading="loading"
    :disabled="loading"
    :search-input.sync="searchKeyWord"
    item-value="id"
    @keyup.enter="search"
    @change="emit"

  >
    <!-- <template v-slot:item="{ item }">
      {{ item.id + ' - ' + item.name }}
    </template> -->
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          <strong>{{ message }}</strong>
        </v-list-item-title>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    value: {},
    product: { type: Object },
  },
  data: () => ({
    customers: [],
    loading: false,
    customerId: null,
    searchKeyWord: '',
    message: '',
  }),
  watch: {
    customer: {
      handler() {
        this.customers = []
        this.customers.push(this.customer)
        this.customerId = this.customer.id
      },
    },
    value(val) {
      this.customerId = val
      if(val){
        this.searchId(val)
      }

    },
  },
  created() {
    this.customerId = this.value
    this.searchId(this.value)
  },
  computed: {},
  methods: {
    emit() {
      this.$emit('input', this.customerId)
    },
    search() {
      this.loading = true
      this.message = 'Carregando aguarde.'
      axios
        .get('helper/get_compositions?search=' + this.searchKeyWord)
        .then(response => {
          this.customers = response.data
          if (this.customers.length == 0) {
            this.message = 'Nenhum registro encontrado.'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchId(id) {
      if (id == '' || id == null) {
        return
      }
      this.productId = id

      this.loading = true
      this.message = 'Carregando aguarde.'
      axios
        .get('helper/get_compositions/' + id)
        .then(response => {
          this.customers =  response.data
          this.customerId = id
          if (this.customers.length == 0) {
            this.message = 'Nenhum registro encontrado.'
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>
</style>
